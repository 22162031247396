/** @jsx jsx */
import { jsx } from 'theme-ui'
const Button = ({
  variant = 'primary',
  ...props
}) =>
  <button
    {...props}
    sx={{
      appearance: 'none',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      fontSize: 'inherit',
      fontWeight: '500',
      m: 0,
      p: 1,
      border: '1px solid',
      borderRadius: 0,
      // pass variant prop to sx
      variant: `buttons.${variant}`
    }}
  />
export default Button