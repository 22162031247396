/** @jsx jsx */

import React from "react"
import { Global } from '@emotion/core'
import { Styled, jsx } from "theme-ui"
import { Flex, Box } from "rebass"
import { Link } from 'gatsby'
import List from './list'
import NavLink from './nav-link'
import Button from './button'
import ColorMode from './color-mode'
import Obfuscate from "react-obfuscate"; 

import FullscreenMenu from "./fullscreen-menu"

const Container = props =>
  <div
    {...props}
    sx={{
      maxWidth: `container`,
      mx: 'auto',
      px: 3,
    }}
  />

class ZebraLayout extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleFullscreenMenu = this.toggleFullscreenMenu.bind(this)
  }
  
  toggleFullscreenMenu() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {

    const { children, aside } = this.props;
    const { isOpen } = this.state;

    const bodyStyles = {
      body: {
        margin: 0
      }
    }

    const pinterest = "https://www.pinterest.co.uk/annawoodhead16/";
    const instagram = "https://www.instagram.com/anna.woodhead.16/";
    const email = "mailto:me@annawoodhead.com";

    return (
       // Styled.root passes on the styles defined in /gatsby-theme-plugin-ui/styles
      // to HTML elements (e.g. h1, p, a).
      <Styled.root>
        <Global styles={bodyStyles} />
        <Flex
          sx={{
            flexWrap: 'wrap',
            height: isOpen ? "100vh" : "auto",
            overflow: isOpen ? "hidden" : "auto",
          }}>
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: 256
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: ["auto", '100vh']
              }}>
              <Box
                as="header"
                sx={{
                  p: 3,
                  variant: 'layout.header'
                }}>
                <Flex>
                  <Box>
                    <Styled.h1
                      as="h1"
                      sx={{
                        fontSize: 4,
                        mb: 2
                      }}>Anna Woodhead</Styled.h1>
                    <Styled.h2
                      as="h2"
                      sx={{
                        fontSize: 2
                      }}
                      >Textile Artist</Styled.h2>
                  </Box>
                  {/* <ColorMode /> */}
                  <Box sx={{ mx: 'auto' }}></Box>
                  <Box
                    sx={{
                      variant: 'styles.navlink',
                      display: ["block", "none", "none"]                    
                    }}>
                    <Button variant='primary' onClick={this.toggleFullscreenMenu}>Menu</Button>
                  </Box>
                </Flex>
              </Box>
              <Box
                sx={{
                  flex: '1 1 auto',
                  px: 3,
                  display: ["none", "block"],
                  variant: "layout.menu"
                }}>
                <Box
                  sx = {{
                    fontSize: 3
                  }}>
                  <List>
                    <li><NavLink large as={Link} to='/'>Walberswick</NavLink></li> 
                  </List>
                </Box>
                <Box
                  sx = {{
                    pt: 2,
                    fontSize: 2
                  }}>
                  <List>
                    <li><NavLink as={Link} to='/workshops'>Workshops</NavLink></li>
                    <li><NavLink as={Link} to='/about'>About</NavLink></li>
                  </List>
                </Box>
                <Box 
                  sx = {{
                    pt: 2,
                    fontSize: 1
                  }}>
                  <NavLink small target="_blank" href={pinterest}>pinterest</NavLink> | <NavLink small target="_blank" href={instagram}>instagram</NavLink> | <Obfuscate
                      email="me@annawoodhead.com"
                      headers={{
                      subject: "A question about www.annawoodhead.com"
                      }}
                      sx = {{
                        variant: "a.navlink"
                      }}
                  >email</Obfuscate>
                </Box>
              </Box>
              <Box
                as="footer"
                variant="footer"
                sx={{
                  p: 3,
                  display: ["none", "block"],
                  variant: "layout.aside"
                }}>
                  {aside}
              </Box>
            </Box>
          </Box>
          <Box
            as="main"
            sx={{
              p: 3,
              flexGrow: 99999,
              flexBasis: 0,
              // This property has to be adjusted to match the breakpoints which is a pain maybe calculate 
              minWidth: 375,
              variant: "layout.main"
            }}>
              <Container>{children}</Container>            
          </Box>
        </Flex>
          
        <FullscreenMenu
          open={isOpen}
          onChange={open => this.setState({ isOpen: open })}
          toggleFullscreenMenu={this.toggleFullscreenMenu.bind(this)}
        />
      </Styled.root>
    )
    
  }
}

export default ZebraLayout