/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from "react"
import { Flex, Box } from "rebass"
import { Link } from 'gatsby'
import NavLink from './nav-link'
import List from './list'
import Button from './button'
import Obfuscate from "react-obfuscate"; 


const FullscreenMenu = ({ open, onChange, toggleFullscreenMenu, ...props }) => {
  const pinterest = "https://www.pinterest.co.uk/annawoodhead16/";
  const instagram = "https://www.instagram.com/anna.woodhead.16/";
  const email = "mailto:me@annawoodhead.com";
  
  return (
    <Box
        sx = {{
            display: open ? "block" : "none",
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "text",
            color: "background",
            overflowY: "scroll",
            overscrollBehavior: "contain"
        }}>
        <Box
            as="header"
            variant="header"
            sx={{
                p: 3
            }}>
            <Flex>
                <Box sx={{ mx: 'auto' }}></Box>
                <Box
                sx={{
                    variant: 'styles.navlink'
                }}>
                  <Button variant='secondary' onClick={toggleFullscreenMenu}>Close</Button>
                </Box>
            </Flex>
        </Box>
        <Box
            sx={{
              flex: '1 1 auto',
              p: 3
            }}>
          <Box
            sx = {{
              fontSize: 3
            }}>
            <List>
              <li><NavLink large as={Link} to='/'>Walberswick</NavLink></li> 
            </List>
          </Box>
          <Box
            sx = {{
              pt: 2,
              fontSize: 2
            }}>
            <List>
              <li><NavLink as={Link} to='/workshops'>Workshops</NavLink></li>
              <li><NavLink as={Link} to='/about'>About</NavLink></li>
            </List>
          </Box>
          <Box 
            sx = {{
              pt: 2,
              fontSize: 1
            }}>
            <NavLink small target="_blank" href={pinterest}>pinterest</NavLink> | <NavLink small target="_blank" href={instagram}>instagram</NavLink> |  <Obfuscate
                      email="me@annawoodhead.com"
                      headers={{
                      subject: "A question about www.annawoodhead.com"
                      }}
                      sx = {{
                        variant: "a.navlink"
                      }}
                  >email</Obfuscate>
          </Box>
        </Box>
    </Box>    
  )
}
export default FullscreenMenu