/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'

export default props =>
  <Styled.a
    {...props}
    sx={{
      variant: "a.navlink",
      fontWeight: props.small ? 'normal' : '500',
    }}
  />